/* Fade-in effect for newly added rows */
@keyframes fadeIn {
  from {
    background-color: rgba(255, 99, 71, 0.3); /* Light red color to highlight */
  }
  to {
    background-color: transparent;
  }
}

.fade-in {
  animation: fadeIn 3s ease-out;
}